import {Modal, Row, Col, Collapse} from 'antd';
import {useState, useEffect} from 'react';
import {getAgeMonth} from '../../../functions/fn';
import moment from 'moment/moment';
import noImage from '../../../assets/images/no_image.jpeg';

export const CustomerDetailModal = ({title, visible, onClose, data}) => {
  const {Panel} = Collapse;

  const [pictureIDCard, setPictureIDCard] = useState();
  const [jobCat, setJobCat] = useState();
  const [dataForm, setDataForm] = useState({});

  useEffect(() => {
    if (data?.record && Object.keys(data.record).length > 0) {
      const grade = {
        '': '-',
        A: 'A (0-5)',
        B: 'B (6-15)',
        C: 'C (16-59)',
        D: 'D (60+)',
        '?': '? (ยังไม่ระบุ)',
        V: 'V (VIP)',
        X: 'X (Blocked)',
      };

      const job_cat = {
        employed: 'ทำงาน',
        student: 'นักเรียน',
      };

      if (!!data?.record?.picture_id_card && data?.record?.picture_id_card.includes('googleapis')) {
        setPictureIDCard(data?.record?.picture_id_card);
      }
      const age = getAgeMonth(data?.record?.general?.dob);
      const setFormData = {
        ...data?.record,
        ...data?.record?.general,
        ...data?.record?.education,
        gender: data?.record?.general?.gender === 'M' || data?.record?.general?.gender === 'm' ? 'ชาย' : 'หญิง',
        dob: data?.record?.general?.dob ? moment(data?.record?.general?.dob).format('YYYY-MM-DD') : '',
        age: age?.years ? `${age?.years} ปี ${age?.months} เดือน` : '',
        addressIdCard: data?.record?.address?.full || '',
        addressPresent: data?.record?.address2?.full || '',
        pictureIDCard: pictureIDCard || '',
        grade_after: grade[data?.record?.grade_after] || '',
        grade_before: grade[data?.record?.grade_before] || '',
        job_cat: job_cat[data?.record?.job_cat] || '',
        get_student_loan: data?.record?.education?.get_student_loan === true ? 'กู้' : 'ไม่กู้',
      };
      setDataForm(setFormData);
      setJobCat(data?.record?.job_cat);
    }
  }, [data?.record]);

  const onCloseMain = () => {
    setDataForm({});
    setPictureIDCard(null);
    setJobCat(null);
    onClose();
  };

  const items = [
    {
      key: '1',
      label: 'ข้อมูลบุคคล',
      children: (
        <Row gutter={[16, 16]}>
          {[
            {name: 'id_card', label: 'เลขที่บัตรประชาชน'},
            {name: 'fullname', label: 'ชื่อ-นามสกุล'},
            {name: 'gender', label: 'เพศ'},
            {name: 'dob', label: 'วันเกิด'},
            {name: 'age', label: 'อายุ'},
            {name: 'tel', label: 'เบอร์โทรศัพท์'},
            {name: 'lineId', label: 'ไลน์ไอดี'},
            {name: 'facebook', label: 'เฟซบุ๊ค'},
            {name: 'zipcode', label: 'รหัสไปรษณีย์'},
            {name: 'addressIdCard', label: 'ที่อยู่บัตรปชช',  col: 24},
            {name: 'addressPresent', label: 'ที่อยู่อาศัยปัจจุบัน',  col: 24},
            {name: 'grade_before', label: 'เกรดลูกค้า'},
            {name: 'grade_after', label: 'เกรดหลังทำสัญญา'},
          ].map(({name, label, col}) => {
            const colProps = col ? {span: col} : {span: 12};
            return (
              <Col {...colProps} key={name}>
                <b>{label}:</b> <span style={{color: '#555'}}>{dataForm[name]}</span>
              </Col>
            );
          })}
        </Row>
      ),
    },
    {
      key: '2',
      label: 'ข้อมูลอาชีพ',
      children: (
        <Row gutter={[16, 16]}>
          {jobCat === 'employed'
            ? [
                {name: 'job_cat', label: 'อาชีพ',  col: 24},
                {name: 'career_name', label: 'ลักษณะอาชีพ'},
                {name: 'position', label: 'ตำแหน่งงาน'},
                {name: 'experience', label: 'อายุการทำงาน'},
                {name: 'salary', label: 'รายได้ต่อเดือน'},
                {name: 'company_name', label: 'บริษัท/หน่วยงาน/ร้านที่ทำงาน/กิจการ'},
                {name: 'work_tel', label: 'เบอร์ที่ทำงาน/เบอร์ติดต่อกิจการ'},
                {name: 'work_address', label: 'ที่อยู่บริษัท/หน่วยงาน/ร้านที่ทำงาน/กิจการ',  col: 24},
              ].map(({name, label, col}) => {
                const colProps = col ? {span: col} : {span: 12};
                return (
                  <Col {...colProps} key={name}>
                    <b>{label}:</b> <span style={{color: '#555'}}>{dataForm[name]}</span>
                  </Col>
                );
              })
            : [
                {name: 'job_cat', label: 'อาชีพ',  col: 24},
                {name: 'degree', label: 'ระดับการศึกษา'},
                {name: 'year', label: 'ชั้นปีที่'},
                {name: 'faculty', label: 'ชื่อมหาวิทยาลัย'},
                {name: 'branch', label: 'สาขาวิชา'},
                {name: 'get_student_loan', label: 'กู้กยศ./กรอ.'},
              ].map(({name, label, col}) => {
                const colProps = col ? {span: col} : {span: 12};
                return (
                  <Col {...colProps} key={name}>
                    <b>{label}:</b> <span style={{color: '#555'}}>{dataForm[name]}</span>
                  </Col>
                );
              })}
        </Row>
      ),
    },
    {
      key: '3',
      label: 'ข้อมูลบุคคลเพิ่มเติม',
      children: (
        <Row gutter={[16, 16]}>
          {[
            {name: 'person1FirstName', label: 'ชื่อจริง(1)'},
            {name: 'person1LastName', label: 'นามสกุล(1)'},
            {name: 'person1Tel', label: 'เบอร์โทรศัพท์(1)'},
            {name: 'person1Relationship', label: 'ความสัมพันธ์(1)'},
            {name: 'person2FirstName', label: 'ชื่อจริง(2)'},
            {name: 'person2LastName', label: 'นามสกุล(2)'},
            {name: 'person2Tel', label: 'เบอร์โทรศัพท์(2)'},
            {name: 'person2Relationship', label: 'ความสัมพันธ์(2)'},
            {name: 'person3FirstName', label: 'ชื่อจริง(3)'},
            {name: 'person3LastName', label: 'นามสกุล(3)'},
            {name: 'person3Tel', label: 'เบอร์โทรศัพท์(3)'},
            {name: 'person3Relationship', label: 'ความสัมพันธ์(3)'},
          ].map(({name, label, col}) => {
            const colProps = col ? {span: col} : {span: 12};
            return (
              <Col {...colProps} key={name}>
                <b>{label}:</b> <span style={{color: '#555'}}>{dataForm[name]}</span>
              </Col>
            );
          })}
        </Row>
      ),
    },
  ];

  return (
    <Modal
      title={title || 'ข้อมูลลูกค้า'}
      open={visible}
      onCancel={onCloseMain}
      width={1300}
      footer={null}
      destroyOnClose={true}
      maskClosable={false}>
      <Row gutter={[16, 16]} style={{padding: '20px', fontSize: '16px', lineHeight: '1.6'}}>
        <Col span={24} style={{textAlign: 'center', marginBottom: '20px'}}>
          <img src={pictureIDCard ? pictureIDCard : noImage} alt="ID Card" style={{width: '200px', height: '200px'}} />
        </Col>
      </Row>

      <Collapse defaultActiveKey={['1']} style={{borderRadius: '10px'}}>
        {items.map(({key, label, children}) => (
          <Panel header={<h5 style={{fontSize: '18px', fontWeight: 'bold', color: '#4A4A4A'}}>{label}</h5>} key={key}>
            <div style={{fontSize: '15px', padding: '20px'}}>{children}</div>
          </Panel>
        ))}
      </Collapse>
    </Modal>
  );
};

import React from 'react';
import {Row, Col, Modal, Typography} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {ContainerButton} from '../../../styles/styledComponentGlobal';
import {ButtonTheme, Spinners} from '../../../components';
import {useSaveUnlockMDM} from './hook';

const {Title} = Typography;

const UnlockMDMModal = ({visible, onClose, input, reApi, valueSearch}) => {
  const [saveUnlockMDMFn, saveUnlockMDMLoading] = useSaveUnlockMDM();

  const onCloseMain = () => {
    reApi(valueSearch);
    onClose();
  };

  const onSubmit = async () => {
    const setInput = {
      contract_no: input?.contract_no,
    };

    console.log('setInput: ', setInput);
    const response = await saveUnlockMDMFn(setInput);

    if (response) {
      Modal.success({
        title: 'บันทึกข้อมูลสำเร็จ',
        afterClose: () => {
          onCloseMain();
        },
      });
    }
  };

  return (
    <>
      {saveUnlockMDMLoading && <Spinners />}
      <Modal
        title={'ยืนยันการปลดล็อก MDM'}
        open={visible}
        width={840}
        onCancel={onClose}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}
        centered>
        <Row>
          <Col span={24} align="center" style={{paddingBottom: '20px'}}>
            <ExclamationCircleOutlined style={{color: 'red', fontSize: 48}} />
          </Col>
          <Col span={24}>
            <Title level={3}>{'ท่านยืนยันทำการปลดล็อก MDM ออกจากผู้ให้บริการ MDM และปลดล็อก MDM จาก Apple Business เรียบร้อยแล้ว?'}</Title>
          </Col>
          <Col span={24}>
            <ContainerButton center>
              <ButtonTheme useFor="SUBMIT" title="ยืนยัน" onClick={() => onSubmit()} />
              <ButtonTheme useFor="CANCEL" onClick={() => onClose()} />
            </ContainerButton>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default UnlockMDMModal;

import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'antd';
import { useForm } from 'react-hook-form';
import RenderForm from '../../../constants/global/RenderForm';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../buttons';
import { EditUserForm } from '../../../constants/global/actionform';
import { GET, POST, GET_OPTION_MENU, EDIT_USER_ADMIN } from '../../../services/index';
// import { socketContext } from '../../../utils/socketProvider';
import { containsNumbers } from '../../../functions/fn'
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const EditUserModal = ({ title, visible, onClose, data }) => {
  // const { socket } = useContext(socketContext);

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    clearErrors,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [optionRole, setOptionRole] = useState([]);
  const [optionMenu, setOptionMenu] = useState([]);
  const [record, setRecord] = useState([]);

  useEffect(() => {
    onSetValue();
    if (JSON.stringify(data.record) !== '{}') {
      let permission_data = []
      let actions_data = []
      if (data.record.permissions.length > 0) {
        data.record.permissions.forEach((el,) => {
          permission_data.push(el.menu_head)
          el.actions.map((vl) => {
            return actions_data.push(`${el.menu_head}_${vl}`)
          })
        })
      }

      const dataMenu = data.record.menu.filter((item) => !permission_data.includes(item));

      setRecord([...dataMenu, ...actions_data])
      setValue('menu', [...dataMenu, ...actions_data])

    }


    getOptionMenu();
  }, [data.record]);

  const onSetValue = () => {
    setValue('email', data.record.email);
    setValue('role', data.record.role);
    setValue('firstname', data.record.firstname);
    setValue('surname', data.record.surname);
    setValue('branch', data.record.branch);

  };

  const getOptionMenu = async () => {
    try {
      let res = await GET(GET_OPTION_MENU);
      const { success, result } = res;
      if (success) {
        const option = result.option_role.map((el) => {
          return { value: el, label: el };
        });

        const options_menu = result.option_menu.map((el, i) => {
          let headName = '';
          switch (el.head) {
            case 'StockNonSerial':
              headName = 'Stock Non Serial';
              break;
            case 'BaanChiang':
              headName = 'Interface บ้านเชียง';
              break;
            case 'DownPayment':
              headName = 'ออมดาวน์';
              break;
            case 'SecondHand':
              headName = 'รับซื้อสินค้ามือสอง';
              break;
            default:
              headName = el.head;
              break;
          }
          return {
            title: headName,
            key: el.head,
            children: el.menu.map((val) => {
              return {
                key: val.menu_code,
                title: val.menu_name,
                children: val.actions ? val.actions.map((vl) => {
                  return {
                    key: `${val.menu_code}_${vl.code}`,
                    title: vl.name
                  }
                }) : []
              }
            })
          };
        });

        setOptionRole(option);
        setOptionMenu(options_menu);
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };


  let getAllKeys = (tree) => {
    let result = [];
    tree.forEach((x) => {
      let childKeys = [];
      if (x.children) {
        childKeys = getAllKeys(x.children);
      }
      result.push(...[x.key, ...childKeys]);
    });

    return result;
  };



  const onChangeCustom = (value, ref) => {
    const allKeys = getAllKeys(optionMenu);
    if (ref === 'role') {
      setValue('role', value);
      setValue('menu', [])

      // if (value === 'Admin') {
      //   setValue('menu', allKeys)
      // } else {
      //   setValue('menu', [])
      // }
    } else {
      setValue(ref, value);
    }
  };
  const onSubmit = async (params) => {

    setLoading(true);
    let permissions = []
    let menus = []
    params.menu.forEach((el) => {

      if (el.substring(1, 0) === "M" && containsNumbers(el)) {
        if (el.includes('cancel') || el.includes('approve') || el.includes('view') || el.includes('edit') || el.includes('import') || el.includes('add') || el.includes('excel')) {
          const dataArr = el.split("_")
          const index = permissions.findIndex(pm => pm.menu_head === dataArr[0])
          if (index === -1) {
            permissions.push({ menu_head: dataArr[0], actions: [dataArr[1]] })
          } else {
            permissions[index].actions = [...permissions[index].actions, dataArr[1]]
          }
          menus.push(dataArr[0])
        } else {
          if (!el.includes('-')) {
            menus.push(el)
          }
        }
      }
    })


    const obj = {
      _id: data.record._id,
      email: params.email,
      firstname: params.firstname,
      surname: params.surname,
      role: params.role,
      branch: params.branch,
      menu: [...new Set(menus)],
      permissions
    };

    if (obj.menu.length > 0) {
      try {
        let res = await POST(EDIT_USER_ADMIN, obj);
        const { success, message } = res;
        if (success) {
          Modal.success({
            title: message,
            afterClose: () => {
              data.reApi();
              // socket.emit('command-to-logout', { email: params.email, uid: params.uid });
              onCloseMain();
            },
          });
          onCloseMain();
        }
      } catch (err) {
        const { message, error } = err;
        Modal.error({
          title: message,
          content: error || '',
        });
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      Modal.error({
        title: '',
        content: 'กรุณาเลือกเมนุผู้ใช้งาน' || '',
      });
    }
  };

  const onCloseMain = () => {
    clearErrors();
    onClose('edit');
    localStorage.removeItem('justOnce');
  };

  const onConfirm = (params) => {
    Modal.confirm({
      title: 'ยืนยันแก้ไขสิทธิ์',
      icon: <ExclamationCircleOutlined />,
      content: 'ต้องการแก้ไขสิทธิ์ ใช่หรือไม่',
      onOk() {
        onSubmit(params);
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  return (
    <Modal title={title || 'modal'} open={visible} onCancel={onCloseMain} width={720} footer={null} destroyOnClose={true} maskClosable={false}>
      <form onSubmit={handleSubmit(onConfirm)}>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          formList={EditUserForm({ option: { optionRole, optionMenu, optionBranch: data.options }, onChange: onChangeCustom, data: record })}
          renderButton={
            <>
              <ContainerButton right>
                <ButtonTheme useFor="SUBMIT" title="บันทึก" htmlType="submit" loading={loading} />
                <ButtonTheme useFor="CANCEL" onClick={() => onCloseMain()} />
              </ContainerButton>
            </>
          }
        />
      </form>
    </Modal>
  );
};
import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Wrapper from '../../views/wrapper/Wrapper';
import { openFormModal, Joi, AutoAdmin } from 'joi-auto-admin';
import { contract, customer2 } from '../../utils/auto-admin/schema';
import { getCustomerList, deleteCustomers, updateCustomer, createContract, resetCouponData, resetProductCode } from '../../utils/auto-admin/api';
import { useContract } from '../../hook/useContract';
import { encryptString } from '../../functions/fn';
import { GET, POST, CHECK_CUSTOMER } from '../../services/index';
import { Card, Spin, Modal, Button, Space, Row, Table } from 'antd';
import { PageLayout } from '../../components/pageLayout/PageLayout';
import { UserOutlined, ReloadOutlined } from '@ant-design/icons';
import { CustomerListColumn } from '../../constants/global/columnTableForm';
import { CustomerListSearchForm } from '../../constants/global/SearchForm';
import { CSVLink } from 'react-csv';
import { ContainerButton } from '../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../components/buttons';
import { Icon } from '../../resources/icon';
import moment from 'moment/moment';
import { convertStrToFormat } from '../../functions/fn';
import { UpdateCustomerInfo } from '../../components/modal/CustomerList/UpdateCustomerInfo';
import { UpdateOtherInfo } from '../../components/modal/CustomerList/UpdateOtherInfo';
import { EditCustomerContextProvider } from './EditCustomerProvider';
import { EditRemarkCustomerModal } from '../../components/modal/CustomerList/EditRemark';
import _ from 'lodash'
import { EditSocailIdModal } from '../monitor/chatcone/modal/EditSocialId';
import { useUpdateTagChatconeByProcess } from '../../hook/chatcone';
import { APP_URL } from '../../env'
import { Spinners } from '../../components';
import { EditGuestModal } from './components/modal/EditGuestModal';
import { CustomerDetailModal } from '../../components/modal/CustomerList/CustomerDetailModal';


const defaultType = 'ALL';

const Customer = () => {
  const { dispatchExportContract } = useContract();
  const url = useLocation();
  const qs = new URLSearchParams(url.search);
  const location = window.location;
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dataExport, setDataExport] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  // const [pageState, setPageState] = useState(1);
  const pageRef = useRef(1);
  const sizeRef = useRef(10);
  // const [sizeState, setSizeState] = useState(10);
  const [idCardState, setIdCardState] = useState(null);
  const [nameState, setNameState] = useState(null);
  const [telState, setTelState] = useState(null);
  const [typeState, setTypeState] = useState('USER');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selected, setSelected] = useState([]);
  const [customerData, setCustomerData] = useState(null);
  const [idCardUpdate, setIdCardUpdate] = useState(null);
  const [visibleUpdateCustomer, setVisibleUpdateCustomer] = useState(false);
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [visibleDetailModal, setVisibleDetailModal] = useState(false);
  const [logRemark, setLogRemark] = useState(null);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [valueSearch, setValueSearch] = useState({});
  const [defaultSearch, setDefaultSearch] = useState({ id_card: '', name: '', tel: '', type: '', typeCustomer: '' });

  const [openUpdateOther, setOpenUpdateOther] = useState(false);
  const [id, setId] = useState('')
  const [contractNo, setContactNo] = useState('')
  const [visibleEditSocialIdModal, setVisibleEditSocialIdModal] = useState(false);
  const [updateTagChatconeByProcessFn, updateTagChatconeByProcessLoading] = useUpdateTagChatconeByProcess()
  const { pathname } = useLocation()
  const [visibleEditGuestModal, setVisibleEditGuestModal] = useState(false);

  // const [filter,setFilter] = useState({})

  useEffect(() => {
    getCustomerListData({ page: pageRef.current, size: sizeRef.current, id_card: '', name: '', tel: '', type: '' });
    setDefaultSearch({ id_card: '', name: '', tel: '', type: defaultType, typeCustomer: defaultType });
  }, []);

  // if (location.href.indexOf('reload') == -1 && location.pathname === '/customer') {
  //   // location.reload()
  //   // location.href = location.href + '?reload';
  //   location.href = location.pathname + '?reload';
  // }

  const optionCustomerType = [
    { label: 'ทั้งหมด', value: 'ALL' },
    { label: 'ลูกค้าทั่วไป', value: 'USER' },
    { label: 'ลูกค้าออมดาวน์', value: 'USER_DOWN_PAYMENT' },
    { label: 'ผู้ใช้ใหม่', value: 'GUEST' },
  ];

  const getCustomerListData = async (value) => {
    value.page = value?.page || 1;
    value.size = value?.size || 10;

    pageRef.current = value.page;
    sizeRef.current = value.size;

    value.id_card = value.id_card || '';
    value.name = value.name || '';
    value.tel = value.tel || '';
    value.type = value.type === 'ALL' ? '' : value.type || '';

    const { page, size, id_card, name, tel, type } = value;
    setLoading(true);
    // setPageState(page)
    // setSizeState(size)
    setValueSearch(value);
    setIdCardState(id_card);
    setTelState(tel);
    setNameState(name);
    setTypeState(type);

    if (type === '') {
      setDefaultSearch({ id_card: '', name: '', tel: '', type: defaultType, typeCustomer: defaultType });
    } else {
      setDefaultSearch({ id_card: '', name: '', tel: '', type: type, typeCustomer: type });
    }

    try {
      const res = await getCustomerList(value);
      const { message, data } = res;
      if (message === 'get customer success') {
        setTotalSize(data.total_item);
        setTotalPage(data.total_page);
        const result = data.customers.map((el, i) => {
          return {
            ...el,
            index: i + 1,
            recentContract: el.contract[el.contract.length - 1]?.contract_no || '',
            recentRemark: !!el.remark ? el?.remark[el?.remark?.length - 1]?.remark : '',
            remark: !!el.remark ? el?.remark : '',
            block_payment: !el.block_payment ? el.block_payment : '',
            job_cat: !!el.education && el?.education?.college ? 'student' : 'employed',
            picture_id_card: el.general?.picture_id_card || '',
            prefix: el?.general?.prefix || '',
            fullname: el?.general?.fullname || '',
            dob: !!el?.general?.dob ? moment(el?.general?.dob) : null,
            gender: el?.general?.gender || '',
            addressIdCard: el?.address?.full || '',
            zipcode: el?.address?.zipcode || '',
            lineId: !!el.social_media ? el?.social_media?.line : '',
            facebook: !!el.social_media ? el?.social_media?.facebook : '',
            addressPresent: !!el.address2 ? el.address2?.full : '',
            career_name: !!el.work ? el?.work.career_name : '',
            position: !!el.work ? el?.work.position : '',
            experience: !!el.work ? el?.work?.experience : '',
            salary: !!el.work ? el?.work?.salary : '',
            company_name: !!el.work ? el?.work?.company_name : '',
            work_tel: !!el.work ? el?.work?.work_tel : '',
            work_address: !!el?.work?.work_address ? el?.work?.work_address.full : '',
            degree: !!el.education ? el?.education?.degree : '',
            year: !!el.education ? el?.education?.year : '',
            college: !!el.education ? el?.education?.college : '',
            faculty: !!el.education ? el?.education?.faculty : '',
            get_student_loan: !!el.education ? el?.education?.get_student_loan : '',
            branch: !!el.education ? el?.education.branch : '',
            person1FirstName: !!el.contact_points ? el?.contact_points?.person1.firstname : '',
            person1LastName: !!el.contact_points ? el?.contact_points?.person1.lastname : '',
            person1Tel: !!el.contact_points ? el?.contact_points?.person1.tel : '',
            person1Relationship: !!el.contact_points ? el?.contact_points?.person1.relationship : '',
            person2FirstName: !!el.contact_points ? el?.contact_points?.person2.firstname : '',
            person2LastName: !!el.contact_points ? el?.contact_points?.person2.lastname : '',
            person2Tel: !!el.contact_points ? el?.contact_points?.person2.tel : '',
            person2Relationship: !!el.contact_points ? el?.contact_points?.person2.relationship : '',
            person3FirstName: !!el.contact_points ? el?.contact_points?.person3.firstname : '',
            person3LastName: !!el.contact_points ? el?.contact_points?.person3.lastname : '',
            person3Tel: !!el.contact_points ? el?.contact_points?.person3.tel : '',
            person3Relationship: !!el.contact_points ? el?.contact_points?.person3.relationship : '',
          };
        });

        const dataEx = data.customers.map((el, i) => {
          return {
            คำนำหน้า: el.general?.prefix || '',
            'ชื่อ-นามสกุล': el.general?.fullname.trim() || '',
            เพศ: el.general?.gender || '',
            วันเกิด: el.general?.dob && moment(el.general.dob).format('YYYY-MM-DD'),
            ที่อยู่บัตรปชช: el.address?.full || '',
            รหัสไปรษณีย์: el.address?.zipcode || '',
            เลขบัตรปชช: el?.id_card || '',
            เบอร์โทรศัพท์: el?.tel || '',
            ไลน์ไอดี: !!el.social_media ? el?.social_media?.line : '',
            ที่อยู่ปัจจุบัน: !!el.address2 ? el.address2?.full : '',
            ประเภทงาน: el.job_cat,
            อาชีพปัจจุบัน: !!el.work ? el.work?.career_name : '',
            ตำแหน่งงาน: !!el.work ? el.work?.position : '',
            อายุการทำงาน: !!el.work ? el.work?.experience : '',
            รายได้ต่อเดือน: !!el.work ? el.work?.salary : '',
            บริษัทปัจจุบัน: !!el.work ? el.work?.company_name : '',
            เบอร์โทรที่ทำงาน: !!el.work ? el.work?.work_tel : '',
            ที่อยู่ที่ทำงาน: !!el.work ? el.work?.work_address?.full : '',
            การศึกษาปัจจุบัน: !!el.education ? el.education?.degree : '',
            ชั้นปีที่: !!el.education ? el.education?.year : '',
            ชื่อสถานศึกษา: !!el.education ? el.education?.college : '',
            คณะ: !!el.education ? el.education?.facalty : '',
            'กู้กยศ./กรอ.หรือไม่': !!el.education ? el.education?.get_student_loan : '',
            สาขาวิชา: !!el.education ? el.education?.branch : '',
            ล็อคการชำระ: !!el.block_payment ? el.block_payment : '',
            เกรดลูกค้า: el.grade_before,
            เกรดหลังทำสัญญา: el.grade_after,
            ชื่อจริง1: !!el.contact_points ? el.contact_points.person1.firstname : '',
            นามสกุล1: !!el.contact_points ? el.contact_points.person1.lastname : '',
            เบอร์โทรศัพท์1: !!el.contact_points ? el.contact_points.person1.tel : '',
            ความสัมพันธ์1: !!el.contact_points ? el.contact_points.person1.relationship : '',
            ชื่อจริง2: !!el.contact_points ? el.contact_points.person2.firstname : '',
            นามสกุล2: !!el.contact_points ? el.contact_points.person2.lastname : '',
            เบอร์โทรศัพท์2: !!el.contact_points ? el.contact_points.person2.tel : '',
            ความสัมพันธ์2: !!el.contact_points ? el.contact_points.person2.relationship : '',
            ชื่อจริง3: !!el.contact_points ? el.contact_points.person3.firstname : '',
            นามสกุล3: !!el.contact_points ? el.contact_points.person3.lastname : '',
            เบอร์โทรศัพท์3: !!el.contact_points ? el.contact_points.person3.tel : '',
            ความสัมพันธ์3: !!el.contact_points ? el.contact_points.person3.relationship : '',
            หมายเหตุ: !!el.remark ? el?.remark[el?.remark?.length - 1]?.remark : '',
          };
        });
        setDataSource(result);
        setDataExport(dataEx);
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const openConfirm = () => {
    if (selected && selected.length > 0) {
      Modal.confirm({
        title: 'ยืนยัน',
        icon: <Icon.warningAlert />,
        content: 'ยืนยันการลบข้อมูลลูกค้า?',
        onOk() {
          deleteCustomerlist();
        },
        okText: 'ยืนยัน',
        cancelText: 'ยกเลิก',
      });
    } else {
      Modal.warning({
        title: 'กรุณาเลือกข้อมูลลูกค้าที่ต้องการลบ',
      });
    }
  };

  const deleteCustomerlist = async () => {
    setLoading(true);
    try {
      const res = await deleteCustomers(selected);
      const { message } = res;
      if (message === 'delete customer success') {
        Modal.success({
          title: 'ทำการลบข้อมูลลูกค้าเรียบร้อย',
          afterClose: () => {
            getCustomerListData({ page: 1, size: 10, id_card: '', name: '', tel: '', type: '' });
            clearCheckbox();
          },
        });
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message || '',
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const clearCheckbox = () => {
    setLoading(true);
    setTimeout(() => {
      setSelectedRowKeys([]);
      setSelected([]);
      setLoading(false);
    }, 600);
  };

  const onPageSizeChangeCustom = (pageData, sizeData) => {
    const sizeChange = sizeRef.current !== sizeData;
    if (sizeChange) {
      // setPageState(1)
      pageRef.current = 1;
    } else {
      // setPageState(pageData)
      pageRef.current = pageData;
    }
    sizeRef.current = sizeData;
    let id_card = idCardState;
    let name = nameState;
    let tel = telState;
    let type = typeState;
    const params = { page: pageRef.current, size: sizeRef.current, id_card: id_card || '', name: name || '', tel: tel || '', type: type || '' };
    getCustomerListData(params);
  };

  const reApi = () => {
    if (!!localStorage.getItem('refinancePeriod')) {
      localStorage.removeItem('refinancePeriod');
    }
    reloadData()
  };

  const onClearCustom = (setValue, formSearch) => {
    formSearch.forEach((val) => setValue(val.name, null));
    setIdCardState('');
    setNameState('');
    setTelState('');
    setTypeState('');
    setDefaultSearch({
      id_card: '',
      name: '',
      tel: '',
      type: null,
      typeCustomer: null,
    });
    pageRef.current = 1;
    sizeRef.current = 10;
  };

  const onClearCustomField = () => {
    // defaultSearch.status = null;
    // defaultSearch.statusMonitor = null;
    setDefaultSearch({ ...defaultSearch, type: defaultType, typeCustomer: defaultType });
  };

  const onShowModalCompleteContract = (newValue) => {
    Modal.success({
      content: (
        <div>
          สร้างสัญญาเรียบร้อย
          <div style={{ position: 'relative', height: 32, marginBottom: -32, top: 24 }}>
            <Button onClick={() => dispatchExportContract(newValue.contract_no)}>ดาวน์โหลดสัญญา</Button>
          </div>
        </div>
      ),
      afterClose: () => {
        reApi();
      },
    });
  }

  const handleCreateContract = async (formData, rowData, resultFgf = {}) => {
    const result = await createContract(formData, rowData?._id, resultFgf);

    // กรณีที่มี error จาก friend get friend จะ alert เตือน ถ้ายืนยันจะสามารถบันทึกได้
    if (_.isEmpty(resultFgf) && result?.fgfStatus === 'FGF_NOT_MATCH_CONDITION') {
      Modal.confirm({
        title: 'ยืนยันการสร้างสัญญา?',
        content: (
          <div>
            {result?.error || ''}
          </div>
        ),
        onOk: async () => {
          await handleCreateContract(formData, rowData, result)
        },
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
      });
    } else {
      onShowModalCompleteContract(result)
    }

    /* CHATCONE: 6.1 สร้างสัญญาสำเร็จ (แบบเก่า) */
    if(result?.status ==='ACTIVE' && rowData?.chatcone?.social_id) {
      const setInput = {
        ref_id: result?.contract_no,
        process_type: 'COMPLETE_CONTRACT',
        route_from: `${APP_URL}${pathname}`,
      }
      
      await updateTagChatconeByProcessFn({...setInput});
    }
  }

  const onCreateContract = (rowData) => {
    localStorage.setItem('id_card', encryptString(rowData.id_card));
    resetCouponData()
    resetProductCode()

    const props = {
      schema: Joi.object({ contract }),
      onSubmit: async (formData) => {
        const res = await GET(CHECK_CUSTOMER(rowData.id_card));
        if (res.success && !res.result.isRegister) {
          if (window.confirm('ลูกค้ายังไม่ได้ลงทะเบียน ต้องการสร้างสัญญาต่อหรือไม่')) {
            if (!formData.contract.mail && formData.contract.checkMail) {
              Modal.error({
                content: <div>ไม่สามารถสร้างสัญญาได้ เนื่องจากอีเมล์เดิมไม่พร้อมใช้งาน</div>,
              });
            } else {
              await handleCreateContract(formData, rowData)
            }
          }
        } else {
          if (!formData.contract.mail && formData.contract.checkMail) {
            Modal.error({
              content: <div>ไม่สามารถสร้างสัญญาได้ เนื่องจากอีเมล์เดิมไม่พร้อมใช้งาน</div>,
            });
          } else {
            await handleCreateContract(formData, rowData)
          }
        }
      },
      // title: 'สร้างสัญญา',
      disableSuccessModal: true,
    };

    openFormModal(props);
  };

  const openModal = (record, log, ref) => {
    if (record) {
      if (ref === 'edit_customer') {
        setIdCardUpdate(record.id_card);
        setCustomerData(record);
        setVisibleUpdateCustomer(true);
      } else if (ref === 'remark') {
        setCurrentRecord(record);
        setLogRemark(log);
        setVisibleEditModal(true);
      } else if (ref === 'EDIT_SOCIAL_ID') {
        setCurrentRecord(record);
        setVisibleEditSocialIdModal(true);
      }
      else if (ref === 'EDIT_GUEST') {
        setCurrentRecord(record);
        setVisibleEditGuestModal(true);
      } else if (ref === 'DETAIL') {
        setCustomerData(record);
        setVisibleDetailModal(true);
      }
    }
  };

  const closeModal = () => {
    setVisibleUpdateCustomer(false);
    setVisibleDetailModal(false)
    setCustomerData(null);
  };

  const reloadData = () => {
    getCustomerListData({ page: pageRef.current, size: sizeRef.current, id_card: idCardState, name: nameState, tel: telState, type: typeState });
  };

  const onChangeSelectContract = (value, index) => {
    let data = [...dataSource];
    let selectedIndex = data.findIndex((el) => el.index === index);

    data[selectedIndex].recentContract = value;
    setDataSource(data);
  };

  const onAddOtherInfo = (record) => {
    setId(record?._id)
    setContactNo(record?.contract[0]?.contract_no)
    setOpenUpdateOther(true);
  };

  return (
    <Wrapper>
      <Card>
        {updateTagChatconeByProcessLoading ? <Spinners /> : null}
        <Spin tip="Loading..." spinning={loading}>
          <PageLayout
            searchLayout={{
              title: 'ข้อมูลลูกค้า',
              icon: <UserOutlined />,
              spanSearch: 24,
              formSearch: CustomerListSearchForm({option: {optionCustomerType}, onClearCustomField, defaultSearch}),
              onSearch: (data) => getCustomerListData(data),
              dataSearchDefault: defaultSearch,
              onClearCustom,
            }}
            tableLayout={{
              columns: CustomerListColumn({
                onCreateContract,
                openModal,
                onAddOtherInfo,
                onChangeSelectContract,
              }),
              dataSource: dataSource,
              scroll: {x: 2000},
              rowKey: (record) => record.id_card,
              rowSelection: {
                selections: [Table.SELECTION_NONE],
                selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                  setSelected(
                    selectedRows.map((row) => {
                      return {customer2: {_id: row._id}};
                    }),
                  );
                  setSelectedRowKeys(selectedRowKeys);
                },
              },
              extraContent: (
                <Row
                  style={{
                    width: '100%',
                  }}
                  align="bottom"
                  justify="space-between">
                  <Space>
                    <ButtonTheme loading={loading} useFor="CUSTOM" title={'ดึงข้อมูลใหม่'} icon={<ReloadOutlined />} onClick={() => reloadData()} />
                  </Space>
                  <Space>
                    <ContainerButton right>
                      <ButtonTheme useFor="REJECT" title={'ลบข้อมูลลูกค้า'} disabled={!selectedRowKeys.length > 0} onClick={() => openConfirm()} />
                      <CSVLink data={dataExport} filename={'ข้อมูลลูกค้า'}>
                        <ButtonTheme useFor="DOWNLOAD" title={'EXPORT'} />
                      </CSVLink>
                    </ContainerButton>
                  </Space>
                </Row>
              ),
              pagination: {
                showSizeChanger: true,
                current: pageRef.current,
                pageSize: sizeRef.current,
                onChange: (p, s) => onPageSizeChangeCustom(p, s),
                pageSizeOptions: ['10', '20', '30', '40', '100'],
                total: totalSize || 0,
                showTotal: (totalSize, range) => `รายการทั้งหมด ${convertStrToFormat(totalSize, 'number-comma')} รายการ`,
                defaultPageSize: sizeRef.current,
                defaultCurrent: 1,
              },
            }}
          />
        </Spin>
      </Card>
      <CustomerDetailModal
        visible = {visibleDetailModal}
        title = {"รายละเอียด"}
        onClose = {() => closeModal()}
        data = {{reApi: getCustomerListData, record: customerData, valueSearch}}
      />
      <EditCustomerContextProvider>
        <UpdateCustomerInfo
          visible={visibleUpdateCustomer}
          title={''}
          onClose={() => closeModal()}
          data={{reApi: getCustomerListData, record: customerData, valueSearch}}
        />
      </EditCustomerContextProvider>
      <EditRemarkCustomerModal
        visible={visibleEditModal}
        title={'แก้ไขหมายเหตุ'}
        onClose={() => setVisibleEditModal(false)}
        data={{reApi: getCustomerListData, record: currentRecord, logs: logRemark, valueSearch}}
      />
      <UpdateOtherInfo
        visible={openUpdateOther}
        title={'เพิ่มข้อมูลอื่นๆ'}
        onClose={() => setOpenUpdateOther(false)}
        id={id}
        contractNo={contractNo}
      />

      <EditSocailIdModal
        visible={visibleEditSocialIdModal}
        title={'แก้ไข Social Id'}
        onClose={() => setVisibleEditSocialIdModal(false)}
        data={{
          ref_id: currentRecord?._id,
          ref_name: 'user',
          process_type: 'CUSTOMER_EDIT_SOCIAL_ID',
          chatcone: currentRecord?.chatcone,
          reApi: getCustomerListData,
          valueSearch,
        }}
      />

      <EditGuestModal  visible={visibleEditGuestModal}
        title={'แก้ไขข้อมูลส่วนตัว'}
        onClose={() => setVisibleEditGuestModal(false)}
        data={currentRecord}
        reApi={getCustomerListData}
        valueSearch={valueSearch}/>
    </Wrapper>
  );
};

export default Customer;
